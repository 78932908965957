import { Injectable, Optional } from '@angular/core';
import { PipelineFeatureName } from './pipeline-feature-name.enum';
import { PipelineConfigService } from './pipeline-config.service';

@Injectable({
  providedIn: 'root',
})
export class PipelineFeatureEnableService {
  constructor(@Optional() private readonly pipelineConfigService: PipelineConfigService) {}

  isEnabledFeature(featureName: PipelineFeatureName): boolean {
    if (!this.pipelineConfigService) {
      return false;
    }
    try {
      return Boolean(this.pipelineConfigService.getConfig().features[featureName].enabled);
    } catch (e: unknown) {
      console.warn(`Feature "${featureName}" enabling config has errors. Check config.json`, e);

      return false;
    }
  }
}
