import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, mergeMap, Observable } from 'rxjs';
import { WidgetNoneIdentityProviderService } from './widget-none-identity-provider.service';

@Injectable()
export class WidgetEmbeddedAuthInterceptor implements HttpInterceptor {
  constructor(private widgetNoneIdentityProviderService: WidgetNoneIdentityProviderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.widgetNoneIdentityProviderService.getToken()).pipe(
      mergeMap((token) => {
        if (token) {
          const cloned = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next.handle(cloned);
        }
        return next.handle(req);
      }),
    );
  }
}
