import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BiModule } from '@selfai-platform/bi';

import { CHART_TYPE_LIST, DATA_SOURCE_TYPE_LIST, IMPLEMENTOR_TYPE_LIST } from '@selfai-platform/bi-domain';
import { PageIsReadyComponent } from '@selfai-platform/bi-shared';
import {
  CONFIG_PATH,
  ConfigService,
  DestroyService,
  PACKAGE_VERSION,
  SELFAI_APP_BASE_HREF,
  ScriptService,
  SelfaiAppModules,
  SharedModule,
} from '@selfai-platform/shared';
import { HomePageWidgets, ShellModule, UiLanguages, notFoundRoute } from '@selfai-platform/shell';
import { ToastModule } from 'primeng/toast';
import packageJson from '../../package.json';
import { AppComponent } from './app.component';
import { SelfaiStoreModule } from '@selfai-platform/store';

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        DragDropModule,
        CommonModule,
        SharedModule,
        ToastModule,
        BiModule,
        SelfaiStoreModule,
        PageIsReadyComponent,
        ShellModule.forRoot({
            languages: [UiLanguages.RU, UiLanguages.EN],
            homePageWidgets: [HomePageWidgets.SelfaiBi],
            modules: [SelfaiAppModules.KD],
        }),
        RouterModule.forRoot([notFoundRoute], {
            scrollPositionRestoration: 'enabled',
        })], providers: [
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        {
            provide: SELFAI_APP_BASE_HREF,
            useFactory: setAppBasehref,
            deps: [LocationStrategy],
        },
        ConfigService,
        {
            provide: PACKAGE_VERSION,
            useValue: packageJson.version,
        },
        {
            provide: CHART_TYPE_LIST,
            useValue: ['bar', 'grid', 'line', 'scatter', 'pie', 'label', 'boxplot'],
        },
        {
            provide: DATA_SOURCE_TYPE_LIST,
            useValue: ['linked_database'],
        },
        {
            provide: CONFIG_PATH,
            useValue: '/assets/config/config.json',
        },
        {
            provide: IMPLEMENTOR_TYPE_LIST,
            useValue: ['POSTGRESQL'],
        },
        ScriptService,
        DestroyService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {}

export function setAppBasehref(locStrategy: LocationStrategy) {
  return locStrategy.getBaseHref();
}
